import React from 'react';
import './index.css';
import {
    apiUrl
  } from "../../config.json";
  
const SubscriberProfile=(props)=>{
  let strFolderName = props.data.registrationno.split("_")[0];
  let urlImage = `${apiUrl}uploads/profilepics/${strFolderName}/${props.data.registrationno}_profile.webp`;

        let statusClass="card_profile_status_red";
        switch(props.data.remainingdays){
            case (props.data.remainingdays < 0):
                statusClass = "card_profile_status_red";
                break;
            case (props.data.remainingdays < props.data.duedays):
                statusClass = "card_profile_status_yellow";
                break;
            default:
                statusClass = "card_profile_status_green";
        }
        // return(
        //     <MyContext.Consumer>
        //     {(context)=>{
                
            return (<div>
                    <div className="card_1">
                        <div className="card__front">
                        <div className="card__cover">
                            
                            <div className="package">
                                {`${props.data.categoryname}`}
                            </div>
                            {
                                (props.data.remainingdays < 0) 
                                ? <div className="card_profile_status_red" />
                                : 
                                (props.data.remainingdays > props.data.duedays) 
                                    ? <div className="card_profile_status_green" />
                                    :<div className="card_profile_status_yellow" />
                            }
                            </div>
                        
                        <img className="card_profile"
                            src= {urlImage}  alt={props.data.name} onClick={(e) => props.handleClickProfile(e,props.data)}/>
                        <div className="card__details">
                            <p className="user-name">{props.data.name}</p>
                            <p className="contact-no">Mobile# {props.data.contact}</p>
                            <p className="balance">{`Rs.${props.data.currbalanceamount}/- Balance`}</p>
                            <p className="city">{`${props.data.city}`}</p>
                            <p className="expire">{`${props.data.remainingdays} days left`}</p>
                    </div>
                    </div>
                </div>
            </div>
            )
            //}
        // }
        // </MyContext.Consumer>
        // );
    
}

export default SubscriberProfile;