import React from 'react';
import './profilewidget/index.css';
import './profilefullwidget/index.css';
import SubscriberProfileFull from './profilefullwidget/SubscriberProfileFull';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import ReportIcon from "@material-ui/icons/Report";
import AppsIcon from "@material-ui/icons/Apps";
import { getAllSubscriber } from "../services/subscriberService";
import HorizontalLabelPositionBelowStepper from "./AddNewSubscriber/HorizontalLabelPositionBelowStepper";
import SubscriberProfile from '../components/profilewidget/SubscriberProfile';
import PackageCategory from '../components/PackageCategory';
import Profile from './Profile';
import TodoApp from './todo';
import Dashboard from './Dashboard';

class Subscriber extends React.Component {
  //state = {};

  state = {
    subscriberAll: [],
    currentPage: 1,
    isSuccess: false,
    pageSize: 4,
    searchQuery: "",
    searchText: '',
    selectedCategory1: null,
    sortColumn: { path: "badges", order: "asc" },
    valueTabs: 'all',
    keyTabs:0,
    showAddNew:false,
    showAddNewSecondryAdmin:false,
    showPackageCategory: false,
    ShowMySecondryAdmin:false,
    isDisplaySecondryAdminSettings:false,
    toggleProfile: false,
    cssProfileLi:'container',
    isSelectedProfile: false,
    profileSelectedIndex: -1,
    selectedProfileData:{},
    showDisplayProfile:false,
    loading : false,
    roletypedata : 'SUBSCRIBER',
    parentregistrationno : "",
  };
  getInitialState = () => {
    this.setState({
      subscriberAll: [],
      // anchorEl: undefined,
      menuState: false,
      searchBox: false,
      searchText: '',
      valueTabs: 'all',
      keyTabs:0,
      showAddNew:false,
      showAddNewSecondryAdmin:false,
      showPackageCategory: false,
      ShowMySecondryAdmin:false,
      toggleProfile: false,
      cssProfileLi:'container',
      isSelectedProfile: false,
      profileSelectedIndex: -1,
      selectedProfileData:{},
      showDisplayProfile:false,
      loading:false,
      roletypedata : 'SUBSCRIBER',
      parentregistrationno : this.props.userInfo.registrationno,
    });
  };

  fetchSubscribersByFilter = async (filtertype) => {
    this.setState({
      loading:true,
    })
    let isSecondryAdmin =0;
    let roletypedata = this.state.roletypedata;
    let parentregistrationno = this.props.userInfo.registrationno;
    switch(this.props.userInfo.roletype){
      case 'FULL_CONTROL': 
        isSecondryAdmin=0;
        roletypedata= (this.state.ShowMySecondryAdmin===true) ? 'FULL_CONTROL_SEC_ADMIN' : 'SUBSCRIBER_ADMIN';
        break;
      case 'FULL_CONTROL_SEC_ADMIN': 
        isSecondryAdmin=1;
        parentregistrationno = this.props.userInfo.parentregistrationno;
        roletypedata= 'SUBSCRIBER_ADMIN';
        break;
      case 'SUBSCRIBER_ADMIN': 
        isSecondryAdmin=0;
        roletypedata= (this.state.ShowMySecondryAdmin===true) ? 'SUBSCRIBER_SEC_ADMIN' : 'SUBSCRIBER';
        break;
      case 'SUBSCRIBER_SEC_ADMIN': 
      isSecondryAdmin=1;
      parentregistrationno = this.props.userInfo.parentregistrationno;    
        break;  
      default:
      isSecondryAdmin =0;
    }
    this.setState({
      parentregistrationno
    })
    const records = [{
      "filtertype": filtertype,
      "parentregistrationno" : parentregistrationno,
      "roletype" : roletypedata,
    }];
    const buildRecords = JSON.stringify(records).replace(/"/g, '\"');
    let response = null;
    try {
      response = await getAllSubscriber(buildRecords);
      if(response)
      {
      if (response.status === 200) {
        return response;
      } else {
        alert(`Fetching of subscriber - failed!`);
      }
    }
    else{
      console.log('Fetching of subscriber - failed');
    }
    } catch (ex) {
      if(response)
      {
      if (ex.response && ex.response.status === 400) {
        const errors = {
          ...this.state.errors
        };
        this.setState({
          errors
        });
      }
    }
    }
  }

  componentDidMount = async () => {
    this.getInitialState();
    if (this.props.userInfo) {
      let subscriberAll = null;
      if(localStorage)
        subscriberAll = localStorage.getItem('subscriberAll');
      if(subscriberAll){
        subscriberAll= JSON.parse(subscriberAll);
        // const resultReset  = await this.fetchSubscribersByFilter(this.state.valueTabs);
        // localStorage.setItem('subscriberAll',JSON.stringify(resultReset.data[0]));
      }
      else{
        const result = await this.fetchSubscribersByFilter(this.state.valueTabs);
        subscriberAll= result.data[0];
        if(localStorage)
          localStorage.setItem('subscriberAll',JSON.stringify(subscriberAll));
      }
      
      this.setState({
        subscriberAll,
        isSuccess: false,
        loading:false,
      });
    }
  }

  handleRequestClose = () => {
    this.setState({
      menuState: false,
      searchBox: false,
      
    });
  };

  onSearchBoxSelect = () => {
    this.setState({
      searchBox: !this.state.searchBox
    })
  };

  handleUpdateSearchText=(evt)=> {
      if(evt){
        this.setState({
          searchText: evt,
        });
    }
  }
  handleLastDeleteTextKeyDown=(e)=> {
      if(e.target.value.length === 1 && e.keyCode === 8){
        this.setState({
          searchText: '',
        });
    }
  }
  handleResetSearchText=()=>{
        this.setState({
          searchText: "",
        });
  }
  handleResetDashborad=()=>{
      if(this.state.showAddNew){
        this.setState({
          showAddNew:false,
        });
      }
  }
  constructor(props) {
    super(props);
  }
  
  handleChangeTabs = async(event, value) => {
    
    let valueTabs = 'all';
    if(value===0){
      valueTabs = 'all';
    }
    else if(value===1){
      valueTabs = 'yellow';
    }
    else if(value===2){
      valueTabs = 'green';
    }
    else{
      valueTabs = 'red';
    }
    const result  = await this.fetchSubscribersByFilter(valueTabs);
      this.setState(
        {
          keyTabs: value,
          valueTabs,
          subscriberAll: (result)?result.data[0]:[],
          isSuccess: false,
          searchText:'',
          loading:false,
        });
        if(localStorage)
          (value===0) && localStorage.setItem('subscriberAll',JSON.stringify(result.data[0]));
  };
  handleAddNewSubscriber=()=>{
    this.setState({
      showAddNew:true,
    })
  }
  handleAddNewSecondryAdmin=()=>{
    this.setState({
      showAddNew:true,
      showAddNewSecondryAdmin:true,
      // ShowMySecondryAdmin:false,
      showDisplayProfile:false,
    })
  }
  handleShowMySecondryAdmin=()=>{
    this.setState({
      ShowMySecondryAdmin : (this.state.ShowMySecondryAdmin === true) ? false : true,
      showDisplayProfile:false,
    })
  }
  handleShowPackageCategory=()=>{
    this.setState({
      showPackageCategory : !this.state.showPackageCategory,
      showDisplayProfile:false,
    })
  }
  handleAddNewSecondryAdminClose=()=>{
    this.setState({
      showAddNew:false,
      showAddNewSecondryAdmin:false,
      // ShowMySecondryAdmin:false,
    })
  }

  handleDisplayProfile=()=>{
    let isSecondryAdmin =false;
    switch(this.props.userInfo.roletype){
      case 'FULL_CONTROL_SEC_ADMIN': 
        isSecondryAdmin=true;
        break;
      case 'SUBSCRIBER_SEC_ADMIN': 
        isSecondryAdmin=true;
        break;  
      default:
      isSecondryAdmin =false;
    }
    this.setState({
      showDisplayProfile:true,
      isDisplaySecondryAdminSettings: isSecondryAdmin,
    })
  }
  handleDisplayProfileClose=()=>{
    this.setState({
      showDisplayProfile:false,
    })
  }
  resetData=async ()=>{
    const result  = await this.fetchSubscribersByFilter(this.state.valueTabs);
    const subscriberAll= result.data[0];
    if(localStorage)
      (this.state.valueTabs==='all') && localStorage.setItem('subscriberAll',JSON.stringify(subscriberAll));
    this.setState({
      toggleProfile:false,
      cssProfileLi:'container',
      isSelectedProfile:false,
      subscriberAll,
      loading:false,
    })
  }
  handleProfileFull=async (context)=>{
    context.isProfileSelected = false;
    //const valueTabs = 'yellow';
    //const result  = await this.fetchSubscribersByFilter(this.state.valueTabs);
    this.setState({
      toggleProfile:false,
      cssProfileLi:'container',
      isSelectedProfile:false,
      loading:false,
    })
  }

  handleClickProfile=(context, data)=>{
    context.selectedProfileData =data;
    context.isProfileSelected = true;
    // setShowProfile(true);
    this.setState({
      cssProfileLi:'container_hide',
      isSelectedProfile:true,
      selectedProfileData:data,
      toggleProfile:true,
    });
  }
  
  render() {
    const theme = createMuiTheme({
      overrides: {
        MuiButton:{
          root:{
            "font-size" : "2.5vmin",
            "min-width": "4vmin",
            "background-color" : "#28a745",
            "margin-left":"0.5vmin",
            },
        },
        MuiFormControl:{
          marginNormal: {
            "margin-top":"0.25vmin",
            "margin-bottom":"0.25vmin",
          },

        },
        MuiInputBase: {
          root:{
            color: "#240C9A",
            "font-size" : "1vmin",
          },
          input: {
            width: "8vmin",
          },

        },
        MuiInput: {
          input: {
            width: "100%",
            "font-size": "2.5vmin",
            "text-transform": "uppercase",
            "font-color": "#240C9A",
        
        },
        },
        MuiTabs:{
          root:{
            backgroundColor: "#28a745",
          },
        fixed:{
          width: '100vw',
        }
      },
        
      },
      MuiSvgIcon: {
        root:{
          902:{
            fill: 'white',
            width: '5vm',
            height: '5vm',
            display: 'inline-block',
            fontsize: '1.5rem',
            transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            flexshrink: '0',
            userselect: 'none',
          },
          310:{
            fill: 'white',
            width: '5vm',
            height: '5vmm',
            display: 'inline-block',
            fontsize: '1.5rem',
            transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            flexshrink: '0',
            userselect: 'none',
        },
      },
    },
    
    });

    let filteredSubscriber = this.state.subscriberAll && this.state.subscriberAll.filter(
      (subscriber)=>{
        return (
          ((subscriber.contact)?subscriber.contact.indexOf(this.state.searchText) !== -1 : false) ||
          ((subscriber.name)?subscriber.name.toLowerCase().indexOf(this.state.searchText.toLowerCase()) !== -1 : false) ||
          ((subscriber.city)?subscriber.city.toLowerCase().indexOf(this.state.searchText.toLowerCase()) !== -1 : false) ||
          ((subscriber.address)?subscriber.address.toLowerCase().indexOf(this.state.searchText.toLowerCase()) !== -1 : false) ||
          ((subscriber.remarks)?subscriber.remarks.toLowerCase().indexOf(this.state.searchText.toLowerCase()) !== -1 : false) ||
          ((subscriber.categoryname)?subscriber.categoryname.toLowerCase().indexOf(this.state.searchText.toLowerCase()) !== -1 : false) ||
          ((subscriber.state)?subscriber.state.toLowerCase().indexOf(this.state.searchText.toLowerCase()) !== -1 : false) 
        );
      }
    );
      return (
      // <MyContext.Consumer>
      // {(context)=>(       
      (this.state.showDisplayProfile===true)?
        <Profile 
        handleDisplayProfileClose = {this.handleDisplayProfileClose}
        handleAddNewSecondryAdmin = {this.handleAddNewSecondryAdmin}
        handleShowMySecondryAdmin = {this.handleShowMySecondryAdmin}
        handleShowPackageCategory = { this.handleShowPackageCategory }
        userInfo = {this.props.userInfo}
        parentregistrationno = {this.state.parentregistrationno}
        isDisplaySecondryAdminSettings = {this.state.isDisplaySecondryAdminSettings}
        ShowMySecondryAdmin = {this.state.ShowMySecondryAdmin}
        resetData = {this.resetData}
        />
      :
      <div className="containerDataentryMain">
        
        {
          // (this.state.showPackageCategory===true) ?  <PackageCategory /> : null

          (this.state.showAddNew===true)?
          <div className="AppContainerMainPlaceholder">
            <HorizontalLabelPositionBelowStepper userInfo={this.props.userInfo} 
            showAddNewSecondryAdmin={this.state.showAddNewSecondryAdmin} 
            resetData={this.resetData}
            handleAddNewSecondryAdminClose = {this.handleAddNewSecondryAdminClose} />
          </div>
          
          :
          <div className="AppContainerMainPlaceholder">
          {
          (this.state.isSelectedProfile===false)
          ?
          <>  
          {
          (this.state.showPackageCategory===true)
          ?
            <PackageCategory handleShowPackageCategory = {this.handleShowPackageCategory}
            parentregistrationno = {this.state.parentregistrationno}
            registrationno = {this.state.registrationno}
            />
          :        
          <>
            <Dashboard handleUpdateSearchText={this.handleUpdateSearchText}
                              handleLastDeleteTextKeyDown = {this.handleLastDeleteTextKeyDown}
                              valueSearchText={this.state.searchText} 
                              handleResetSearchText={this.handleResetSearchText}
                              handleAddNewSubscriber = {this.handleAddNewSubscriber} 
                              handleDisplayProfile = {this.handleDisplayProfile} 
                              handleResetDashborad = { this.handleResetDashborad }
                              showAddNew = { this.state.showAddNew }
                              resetData = { this.resetData }
                              />
          <div >
          <MuiThemeProvider theme={theme}>
            
            <Tabs
              value={this.state.keyTabs}
              onChange={this.handleChangeTabs}
              variant="fullWidth"
              indicatorColor="secondary"
              textColor="secondary"
              scrollButtons="on"
              >
            
              <Tab icon={<AppsIcon/>} 
              style=
              {(this.state.keyTabs===0)
                ?
                {
                  backgroundColor: '#201085', 
                  color:'#fff'
                }
                :
                {
                  backgroundColor: '#3F51B5', 
                  color:'#bad6f1'
                }
              }/>
              <Tab icon={<ReportIcon/>} 
              style=
              {(this.state.keyTabs===1)
                ?
                {
                  backgroundColor: '#201085', 
                  color:'#fff'
                }
                :
                {
                  backgroundColor: '#3F51B5', 
                  color:'#bad6f1'
                }
              }/>
              <Tab icon={<OfflineBoltIcon/>} 
              style=
              {(this.state.keyTabs===2)
                ?
                {
                  backgroundColor: '#201085', 
                  color:'#fff' 
                }
                :
                {
                  backgroundColor: '#3F51B5', 
                  color:'#bad6f1' 
                }
              }/>
              <Tab icon={<RemoveCircleIcon/>} 
              style=
              {(this.state.keyTabs===3)
                ?
                {
                  backgroundColor: '#201085', 
                  color:'#fff' 
                }
                :
                {
                  backgroundColor: '#3F51B5', 
                  color:'#bad6f1' 
                }
              }/>
            </Tabs>
        
          
          </MuiThemeProvider>
            
          </div>
          <div>
            {
              this.state.subscriberAll && this.state.subscriberAll.length>0 
              &&
                (this.state.loading)
                ?
                <div> 
                  <CircularProgress size={50}  /> 
                </div>
                :
                <TodoApp data={filteredSubscriber} handleClickProfile={this.handleClickProfile} />
            }
          </div>
          </>
              }
          </>
          :
          <div className="row">
          <div className="col-md-6">
              <div className="form-group">
                <div>
                {
                  (this.state.isSelectedProfile===true)
                  ?
                    <MuiThemeProvider theme={theme}>
                      <SubscriberProfileFull data={this.state.selectedProfileData} 
                      handleProfileFullClick={this.handleProfileFull} resetData={this.resetData} currentLoginUser={this.props.userInfo.registrationno} 
                      userInfo = {this.props.userInfo} />
                    </MuiThemeProvider>
                  :
                    <SubscriberProfile data={this.state.selectedProfileData} isSelectedProfile={this.state.isSelectedProfile}
                    handleClickProfile={this.handleClickProfile}/>
                
                }
                </div>
              </div>
            </div>
          </div>
          }
          </div>
      }
      </div>
       );
  }
  
}

export default Subscriber;