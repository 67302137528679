import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CategoryIcon from '@material-ui/icons/Category';
import SettingsIcon from '@material-ui/icons/Settings';
import HelpIcon from '@material-ui/icons/Help';
import AssessmentIcon from '@material-ui/icons/Assessment';
import FaceIcon from '@material-ui/icons/Face';
import LanguageIcon from '@material-ui/icons/Language';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DeleteIcon from '@material-ui/icons/Delete';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import App from '../App';
import '../styles/Profile.css';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',

    backgroundColor: theme.palette.background.paper,
  },
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default function Profile(props) {
  const classes = useStyles();
  const handleShowAdminClick= async()=>{
    await props.handleShowMySecondryAdmin();
    props.resetData();
  }
  const handleAddNewSecondryAdminClick= async()=>{
    await props.handleAddNewSecondryAdmin();
    props.resetData();
  }
  const [isLogout, setIsLogout] = useState(false);
  const handleLogout=()=>{
    if(localStorage){
      localStorage.removeItem("userInfo");
      localStorage.removeItem("subscriberAll");
    }
    setIsLogout(true);
  }
  return (
      <div>
        {
      (isLogout === true)
      ?
        <App />
      :
        <div className={classes.root}>
          <AppBar position="static">
            <Toolbar onClick={props.handleDisplayProfileClose}>
              <IconButton edge="start" className={classes.menuButton} 
              color="inherit" aria-label="menu" >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Account
              </Typography>
              
            </Toolbar>
          </AppBar>
          <div className="avatar">
          {/* <Avatar /> */}
          <div className = "profile-name">{props.userInfo.firstname} {props.userInfo.lastname}</div>
          <div className = "address">{props.userInfo.address}</div>
          </div>
          <Divider />
          <List component="nav">
          {
            (props.isDisplaySecondryAdminSettings === false) ?
            <>
            <ListItem button onClick = {handleAddNewSecondryAdminClick}>
              <ListItemIcon>
                <PersonAddIcon />
              </ListItemIcon>
            <ListItemText primary="Add User" />
            </ListItem>
            <ListItem button onClick = {handleShowAdminClick}>
              <ListItemIcon>
                <FaceIcon />
              </ListItemIcon>
              <ListItemText primary={props.ShowMySecondryAdmin ? "Show My Subscribers" : "Show My Admin"} />
            </ListItem>
            <ListItem button onClick = {props.handleShowPackageCategory}>
              <ListItemIcon>
                <CategoryIcon />
              </ListItemIcon>
              <ListItemText primary="Add Category" />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText primary="Analytics" />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Setting" />
            </ListItem>
            </>
            :
            null
          }
            <ListItem button>
              <ListItemIcon>
                <InsertDriveFileIcon />
              </ListItemIcon>
              <ListItemText primary="Privacy Policy" />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <LanguageIcon />
              </ListItemIcon>
              <ListItemText primary="Language" />
            </ListItem>
            
            <ListItem button>
              <ListItemIcon>
                <BorderColorIcon />
              </ListItemIcon>
              <ListItemText primary="Report a problem" />
            </ListItem>
          
          <ListItem button>
              <ListItemIcon>
                <HelpIcon />
              </ListItemIcon>
              <ListItemText primary="Help Center" />
            </ListItem>
          </List>
          <Divider />
          <List component="nav">
          <ListItem button>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText primary="Clear Cache" />
            </ListItem>
          <ListItem button onClick={handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Log out"  />
            </ListItem>
          
          </List>
      </div>
    }
    </div>
  );
}
