import React, { useState, useEffect } from 'react';
// import Posts from './components/Posts';
import Login from './components/Login2';
import Subscriber from './components/Subscriber';
import {getLoginSubscriber} from "./services/subscriberService";
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import './App.css';
import './styles/dataentry.css';
import './styles/login/css/login.css';
import './styles/material-design-iconic-font/css/material-design-iconic-font.min.css';

const App = () => {
  // const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [currentUser, setCurrentUser] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [showSubscribers, setShowSubscribers] = useState(false);
  const [errors, setErrors] = useState("");
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorContact, setErrorContact] = useState(false);
  
  
  useEffect(() => {
    let userInfo = null;
    if(localStorage)
    userInfo = localStorage.getItem("userInfo");
    if(userInfo){
      setUserInfo(JSON.parse(userInfo));
      setLoading(false);
      setShowSubscribers(true);
    }
  }, []);

  const getUserInfo = async (contact, password) => {
    setLoading(true);
    const records = [{
    "username" : contact,
     "pwsalt" : password
    }];

    
    let buildRecords = JSON.stringify(records).replace(/"/g, '\"');

    try {
      // buildRecords = `[{\"username\":\"${this.state.contact}\",\"pwsalt\":\"${this.state.password}\"}]`;

      const response = await getLoginSubscriber(buildRecords); 
      if(response){
        const userInfo = response.data[0][0];
        delete userInfo["passwordsalt"];
        if(localStorage)
          localStorage.setItem("userInfo",JSON.stringify(userInfo));
        if (response.status === 200) {
          setUserInfo(userInfo);
          setShowSubscribers(true);
          setErrorContact(false);
          setErrorPassword(false);
          setLoading(false);
          return response;
        }else {
        alert(`Fetching of userInfo - failed!`);
      }
    }
    else{
      setErrorContact(true);
      setErrorPassword(true);
      console.log("User Info not found");
      return null;
    }
    } catch (ex) {
        if(ex){
          if (ex.response && ex.response.status === 400) {
            setLoading(false);
            setErrors(ex.response.data);
            if(ex.response.data==='Invalid Contact Number.'){
              setErrorContact(true);
              setErrorPassword(false);
            }
            else{
              setErrorContact(false);
              setErrorPassword(true);
            }
        }
      }
        return null;
    }
    
  };


  const handleLoginClick = (contact, password) => {
      try {
        getUserInfo(contact, password);
      } catch (ex) {
        if(ex){
        if (ex.response && ex.response.status === 400) {
          // const errors = { ...errors };
          // errors.username = ex.response.data;
          setErrors(ex.response.data);
        }
      }
    }
  }

  return (
    <React.Fragment>
      {
        (showSubscribers===false)
        ?
          <Login loading={loading} handleLoginClick={handleLoginClick} errors={errors} errorContact={errorContact} errorPassword={errorPassword} />
        :
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Subscriber userInfo={userInfo} />
          </MuiPickersUtilsProvider>
      }
    </React.Fragment>
  );
};

export default App;
