import React,{ useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import moment from 'moment';
import {register} from "../../services/subscriberService";
import {getTransactionHistory} from "../../services/transactionhistoryService";
import {getAllCategory1} from "../../services/category1Service";
import {getSelectCategory, getCreditBalance} from './Constants';
import {Row} from 'react-bootstrap';
import {
  apiUrl
} from "../../config.json";

const SubscriberProfileFull=(props)=>{
  const [editProfile, setEditProfile]=useState(false);
  const [firstName, setfirstName]=useState(props.data.name.split(' ')[0]);
  const [lastName, setlastName]=useState(props.data.name.split(' ')[1]);
  const [mobile, setMobile]= useState(props.data.contact);
  const [openDlg, setOpenDlg] = useState(false);
  const [categoryname, setCategoryname] = useState(props.data.categoryname);
  const [categoriesList, setCategoriesList] = useState([]);
  const [selectedDateStart, setSelectedDateStart] = useState(moment());
  const [selectedDateEnd,setSelectedDateEnd] = useState(props.data.enddate);
  const [numberofdays, setNumberofdays]= useState(props.data.duedays);
  const [lastbalanceamount,setLastbalanceamount] = useState(props.data.currbalanceamount);
  const [categoryid, setCategoryid] = useState(props.data.category_lastsubs);
  const [categoryCost, setCategoryCost] = useState(props.data.cost_lastsubs);
  const [balanceamount, setBalanceamount] = useState(0);
  const [paidamount, setPaidamount] = useState(0);
  const [selectedCurrency, SetSelectedCurrency] = useState('');
  const [currencies, setCurrencies] = useState([]);
  //const [errors, setErrors] = useState('Error');
  const [loading, setLoading] = useState(false);
  const [openCreditBalance, setOpenCreditBalance] = useState(false);


 const loadInitialData = async () =>{
    const currencies = [
        {
          value: 'RUPEE',
          label: '₹',
        },
        {
          value: 'USD',
          label: '$',
        },
        {
          value: 'EUR',
          label: '€',
        },
        {
          value: 'BTC',
          label: '฿',
        },
        {
          value: 'JPY',
          label: '¥',
        },
      ];
    setCurrencies(currencies);
    let categoriesList = await getAllCategory1(props.data.parentregistrationno);
    if(categoriesList){
    categoriesList = categoriesList.data[0];
    const categoryid = categoriesList[0].id;
    const categoryname = categoriesList[0].categoryname;
    const numberofdays = categoriesList[0].numberofdays;
    const categoryCost =  categoriesList[0].amount;  
    const selectedDateEnd = moment(selectedDateStart).add(numberofdays,'days');
    const balanceamount = categoryCost+lastbalanceamount;

    setCategoriesList(categoriesList);
    setCategoryid(categoryid);
    setCategoryname(categoryname);
    setNumberofdays(numberofdays);
    setCategoryCost(categoryCost);
    setSelectedDateEnd(selectedDateEnd);
    setBalanceamount(balanceamount);
    }
  }

  useEffect(
      ()=>{loadInitialData()}, []
  );

let saveSubscriber = async () => {
    const records = [{
      "registrationno": props.data.registrationno,
      "mobile": mobile,
      "firstname": firstName,
      "lastname": lastName,
      "lastbalanceamount": props.data.currbalanceamount,
      "modifiedby": props.currentLoginUser,
      "istransaction": false
    }];
    const buildRecords = JSON.stringify(records).replace(/"/g, '\"');

    try {
        const response = await register(buildRecords);
        if(response){
          if (response.status === 200) {
            setEditProfile(false);
          }
          else {
            alert(`Registration of subscriber - failed!`);
          }
        }
        else{
          console.log('Registration of subscriber - failed');
        }
        
    }
      catch (ex) {
      if (ex.response && ex.response.status === 400) {
          //setErrors(ex.response.data);
      }
    }
  }
let getAllTransactionHistory = async () => {
    const records = [{
      "registrationno": props.data.registrationno
      }];
    const buildRecords = JSON.stringify(records).replace(/"/g, '\"');

    try {
        const response = await getTransactionHistory(buildRecords);
        if(response){
          if (response.status === 200) {
            setEditProfile(false);
          }
          else {
            alert(`Get TransactionHistory - failed!`);
          }
        }
        else{
          console.log('Get TransactionHistory - failed');
        }
        
    }
      catch (ex) {
      if (ex.response && ex.response.status === 400) {
          //setErrors(ex.response.data);
      }
    }
  }
  
  let saveSubscriberWithTransaction = async () => {
    const lastbalanceamount = balanceamount + props.data.currbalanceamount;  
    const records = [{
      "registrationno": props.data.registrationno,
      "category_lastsubs": categoryid,
      "cost_lastsubs": categoryCost,
      "currpaidamount": paidamount,
      "currbalanceamount": balanceamount,
      "startdate": selectedDateStart._d.toISOString().split('T')[0],
      "enddate": selectedDateEnd._d.toISOString().split('T')[0],
      "lastbalanceamount": lastbalanceamount,
      "modifiedby": props.currentLoginUser,
      "istransaction": true,
      "isCreditBalance": false
    }];

    const buildRecords = JSON.stringify(records).replace(/"/g, '\"');

    try {
        const response = await register(buildRecords);
        if(response){
        if (response.status === 200) {
            setEditProfile(false);
            handleCloseDlg();
        }
      else {
        alert(`Registration of subscriber - failed!`);
      }
    }
    else{
      console.log('Registration of subscriber - failed');
    }
    }
      catch (ex) {
      if (ex.response && ex.response.status === 400) {
        //setErrors(ex.response);

      }
    }
  }
  
  let saveSubscriberWithTransactionCreditBalance = async () => {
    const lastbalanceamount = props.data.currbalanceamount;  
    const records = [{
      "registrationno": props.data.registrationno,
      "currpaidamount": paidamount,
      "currbalanceamount": balanceamount,
      "lastbalanceamount": lastbalanceamount,
      "modifiedby": props.currentLoginUser,
      "istransaction": true,
      "isCreditBalance": true
    }];

    const buildRecords = JSON.stringify(records).replace(/"/g, '\"');

    try {
        const response = await register(buildRecords);
        if(response){
        if (response.status === 200) {
            setEditProfile(false);
            handleCloseDlg();
        }
      else {
        alert(`Registration of subscriber - failed!`);
      }
    }
    else{
      console.log('Registration of subscriber - failed');
    }
    }
      catch (ex) {
      if (ex.response && ex.response.status === 400) {
        //setErrors(ex.response);

      }
    }
  }

    const handleDateChangeStart = (date) => {
        const selectedDateEnd = moment(date).add(numberofdays,'days');
        setSelectedDateStart(date);
        setSelectedDateEnd(selectedDateEnd);
      };

    const handleDateChangeEnd = (date) => {
        setSelectedDateEnd(date);
      };
    let handleChangeRadioCategory=(event, value)=>{
        const currCategory = categoriesList.filter(category=> category.categoryname === value);
        const categoryname = currCategory[0].categoryname;
        const numberofdays=currCategory[0].numberofdays;
        setNumberofdays(numberofdays);
        setCategoryname(categoryname);
        const selectedDateEnd = moment(selectedDateStart).add(numberofdays,'days');
        setSelectedDateEnd(selectedDateEnd);
        setCategoryid(currCategory[0].id);
        const categoryCost = currCategory[0].amount;
        setCategoryCost(categoryCost);
        const balanceamount = (categoryCost+lastbalanceamount)-paidamount;
        setBalanceamount(balanceamount);
    }
    const handleChangeCurrency=(value)=>{
        SetSelectedCurrency(value);
    }
    const handleOnchangePaidAmount=(value)=>{
        const balanceamount = (categoryCost+lastbalanceamount) - value;
        setPaidamount(value);
        setBalanceamount(balanceamount);
    }
    const handleOnchangePaidAmountCreditBalance=(value)=>{
        const balanceamount = (lastbalanceamount) - value;
        setPaidamount(value);
        setBalanceamount(balanceamount);
    }
    let handleEditClick=()=>{
        setEditProfile(true);
    }
    let handleSaveClick= async()=>{
        if(openCreditBalance===true) 
        {
            setOpenCreditBalance(false);
            setOpenDlg(false);
            await saveSubscriberWithTransactionCreditBalance();
            
            props.resetData();
            //setEditProfile(true);
        }
        else
        {
            setOpenDlg(false);
            setLoading(true);
            await saveSubscriberWithTransaction();
            setLoading(false);
            props.resetData();
        }
        
    }
    let handleCreditBalanceClick = async()=>{
        setOpenCreditBalance(true);
        setOpenDlg(true);
        setEditProfile(false);
        
        const balanceamount = (lastbalanceamount)-paidamount;
        setBalanceamount(balanceamount);
    }
    let handleSaveInfoClick= async()=>{
        setLoading(true);
        await saveSubscriber();
        setLoading(false);
        props.resetData();
    }
    let handleOpenDlg=(callBack)=>{
        setOpenDlg(true);
        setEditProfile(false);
    }
    let handleCloseDlg=()=>{
        setOpenDlg(false);
        setOpenCreditBalance(false);
        setPaidamount(0);
    }
    const classes = makeStyles(theme => ({
      root: {
        width: '100vw',
        height: '100vh',
        // backgroundColor: theme.palette.background.paper,
        backgroundColor: '#fff',
        marginleft:'5vmin',

      },
    }));
    // const themeMui = createMuiTheme({
    //   overrides: {
    //     MuiTypography:{
    //       body1:{
    //         "margin":"0",
    //         "padding":"0",
    //         "font-family": "Roboto, Helvetica, Arial, sans-serif",
    //         "font-size" : "4vmin",
    //         },
    //     },
    //     MuiFormLabel:{
    //       root:{
    //         "margin":"0",
    //         "padding":"0",
    //         paddingTop: "10px",
    //         "font-family": "Roboto, Helvetica, Arial, sans-serif",
    //         "font-size" : "3vmin",
    //         },
    //     },
    //     MuiInputBase:{
    //       input:{
    //         // "margin":"0",
    //         // "padding":"0",
    //         "font-family": "Roboto, Helvetica, Arial, sans-serif",
    //         "font-size" : "4vmin",
    //         },
    //     },
    //     MuiFormControlLabel:{
    //       root:{
    //         "margin":"0",
    //         "padding":"0",
    //         "font-family": "Roboto, Helvetica, Arial, sans-serif",
    //         "font-size" : "4vmin",
    //         },
    //     },
    //     MuiButton:{
    //       root:{
    //         "font-size" : "2.5vmin",
    //         "min-width": "4vmin",
    //         "background-color" : "#28a745",
    //         "margin-left":"0.5vmin",
    //         },
    //     },
    //   }
    // });
  let strFolderName = props.data.registrationno.split("_")[0];
  const sendToMobile = (mobile.length===10)? mobile : props.userInfo.contact
  let urlImage = `${apiUrl}uploads/profilepics/${strFolderName}/${props.data.registrationno}_profile.webp`;
  const whatsappMessage= `https://wa.me/91${sendToMobile}?text=Dear ${firstName.toUpperCase()},
  %0aPlease recharge your subscription in time,
  %0a%0aRs. *${props.data.currbalanceamount}*/- Balance
  %0a_${props.data.remainingdays}_ days left
  %0a%0a%0a_Thanks and Regards,_
  %0a%0a*${props.userInfo.firstname} ${props.userInfo.lastname}*
  %0a_Admin_`;

        return(
          <div>
            {
              (openDlg===false)
              ?
            <div className="containerDataentryMain">
            <AppBar position="static">
                    <Toolbar>
                      <IconButton edge="start" className={classes.menuButton} 
                      color="inherit" aria-label="menu" onClick={(e)=>props.handleProfileFullClick(e)} >
                        <CloseIcon />
                      </IconButton>
                      <Typography variant="h6" className={classes.title}>
                        Manage Subscriber
                      </Typography>
                      <div className="pickupbizdashboard">
                      <IconButton edge="start" className={classes.menuButton} 
                      color="inherit" aria-label="menu" onClick={()=>getAllTransactionHistory()} > 
                      <LibraryBooksIcon style= {{color: '#fff'}}/>
                        </IconButton>
                      </div>
                      <div className="pickupbizdashboard">
                        <a href={whatsappMessage} target="_blank">
                          <ContactMailIcon 
                          style= {{color: '#fff'}}/>
                        </a>
                      </div>
                    </Toolbar>
                </AppBar>
            <div className="containerfull">
                    <div className="cardfull">
                        <div className="card__frontfull">
                        <div className="card__coverfull">
                            <p className="packagefull">{`${props.data.categoryname}`}</p>
                            <>
                            {
                            (editProfile===true) ? (<Row><Button variant="contained" color="primary"  
                            onClick={handleSaveInfoClick}>Save</Button>
                            <Button disabled={loading} variant="contained" color="primary">
                            Delete
                          </Button></Row>)
                            : null
                            }
                            </>
                        </div>
                        <img className="card_profilefull"
                            src= {urlImage}  alt={props.data.name} onClick = {(e)=>props.handleProfileFullClick(e)}/>
                        {loading && <CircularProgress size={30}  />}
                        <div className="card__detailsfull">
                            <div>
                            {
                            (editProfile===true)
                            ?
                            (
                                <div>
                                    <div >
                                    <TextField
                                        id="firstName"
                                        value={firstName}
                                        onChange={e => setfirstName(e.target.value)}
                                        margin="normal"
                                        placeholder="First Name"
                                        fullWidth
                                    />
                                    </div>        
                                    <div >
                                        <TextField
                                            id="lastName"
                                            placeholder="Last Name"
                                            value={lastName}
                                            onChange={e => setlastName(e.target.value)}                                            margin="normal"
                                            fullWidth
                                        />
                                    </div>
                                </div> 
                            )
                            :
                            <p className="user-namefull" onClick={handleEditClick}>{firstName} {lastName}</p>
                            }
                             </div>
                            <div >
                            {
                                (editProfile===true)
                                ?
                                (
                                    <div >
                                        <TextField
                                            id="mobile"
                                            placeholder="Mobile"
                                            value={mobile}
                                            onChange={e => setMobile(e.target.value)}                                            margin="normal"
                                            fullWidth
                                        />
                                    </div>    
                                )
                                :
                                <p className="contact-nofull">Mobile# {mobile}</p>
                            }
                            </div>
                            <div onClick={handleOpenDlg}>
                            <p className="balancefull">{`Rs.${props.data.currbalanceamount}/- Balance`}</p>
                            <p className="cityfull">{`${props.data.city}`}</p>
                            <p className="expirefull">{`${props.data.remainingdays} days left`}</p>
                            </div>
                            <div>
                            <Button variant="contained" color="primary"  
                            onClick={handleCreditBalanceClick}>Credit Balance</Button>
                            </div>
                    </div>
                  </div>
                </div>
            </div>
            </div>  
            
          
           :
           <div className="containerDataentryMain">
             <AppBar position="static">
                    <Toolbar onClick={handleCloseDlg}>
                      <IconButton edge="start" className={classes.menuButton} 
                      color="inherit" aria-label="menu" >
                        <CloseIcon />
                      </IconButton>
                      <Typography variant="h6" className={classes.title}>
                        { (openCreditBalance===true) ? 'Manage Balance' : 'Renewal of Subscriber'}
                      </Typography>
                      
                    </Toolbar>
                </AppBar>
           <div className="containerDataentry">
             {(openCreditBalance===false)
                ?
                getSelectCategory(categoriesList, categoryname, handleChangeRadioCategory,
                    selectedDateStart,handleDateChangeStart,selectedDateEnd, handleDateChangeEnd,
                    selectedCurrency, handleChangeCurrency, categoryCost, lastbalanceamount, paidamount, handleOnchangePaidAmount, balanceamount, 
                    currencies, props.resetData)
                :
                getCreditBalance(
                    selectedCurrency, handleChangeCurrency, lastbalanceamount, paidamount, handleOnchangePaidAmountCreditBalance, balanceamount, 
                    currencies, props.resetData)
            }
            <Button onClick={handleSaveClick} variant="contained" disabled={loading} color="primary">
               Save
             </Button>
           </div>
             
             </div>    
          
          }
        </div>
       
        );
    
}

export default SubscriberProfileFull;