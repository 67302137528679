import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import {DatePicker} from 'material-ui-pickers';
// import TextField from '@material-ui/core/TextField';

export const getSelectCategory=(categoriesList,categoryname,handleChangeRadioCategory, 
    selectedDateStart,handleDateChangeStart,selectedDateEnd, handleDateChangeEnd,
    selectedCurrency, handleChangeCurrency, categoryCost, lastbalanceamount, paidamount, handleOnchangePaidAmount, balanceamount, 
    currencies, resetData)=>{
   
    return <div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <FormControl component="fieldset" required>
                  <FormLabel component="legend">Select Category</FormLabel>
                  <RadioGroup
                    className="d-flex flex-row"
                    aria-label="gender"
                    name="category1"
                    value={categoryname}
                    onChange={handleChangeRadioCategory}
                  >
                    {
                      categoriesList.map(category=>{
                        return (<FormControlLabel value={category.categoryname} control={<Radio color="primary"/>} label={category.categoryname}/>)
                      })
                    }
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>
          <div className="row">
          <div className="col-md-6">
              <div className="form-group">
                Start Date : 
            <div key="basic_day" className="picker">
              <DatePicker
                fullWidth
                value={selectedDateStart}
                onChange={handleDateChangeStart}
                animateYearScrolling={false}
                leftArrowIcon={<i className="zmdi zmdi-arrow-back"/>}
                rightArrowIcon={<i className="zmdi zmdi-arrow-forward"/>}
              />
            </div>
                    
              </div>
          </div>
          <div className="col-md-6">
              <div className="form-group">
                End Date : 
                <div key="basic_day" className="picker">
              <DatePicker
                fullWidth
                value={selectedDateEnd}
                onChange={handleDateChangeEnd}
                animateYearScrolling={false}
                leftArrowIcon={<i className="zmdi zmdi-arrow-back"/>}
                rightArrowIcon={<i className="zmdi zmdi-arrow-forward"/>}
              />
            </div>
              </div>
          </div>
          
          </div>
          <div className="row">
          <div className="col-md-6">
          
          <div className="form-group">
            <p>{`Previouse Balance : ${lastbalanceamount}`}</p>        
            <p>{`Package Cost : ${categoryCost}`}</p>        
              <input
                  id="paidAmount"
                  className="inputDataEntry"
                  value={paidamount}
                  onChange={e => handleOnchangePaidAmount(e.target.value)}
                  onClick={e => e.target.value=''}
                  margin="normal"
                  placeholder="Paid Amount"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  
                />
                <span className="inputDataEntryBalance">Balance: <strong>{balanceamount}</strong></span>
                </div>
          </div>
          </div>
        
        </div>
      }

export const getCreditBalance=(
        selectedCurrency, handleChangeCurrency, lastbalanceamount, paidamount, handleOnchangePaidAmount, balanceamount, 
        currencies, resetData)=>{
       
        return <div>
              
              <div className="row">
              <div className="col-md-6">
              
              <div className="form-group">
                <p>{`Previouse Balance : ${lastbalanceamount}`}</p>        
                  <input
                      id="paidAmount"
                      className="inputDataEntry"
                      value={paidamount}
                      onChange={e => handleOnchangePaidAmount(e.target.value)}
                      onClick={e => e.target.value=''}
                      margin="normal"
                      placeholder="Paid Amount"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}                      
                    />
                    <span className="inputDataEntryBalance">Balance: <strong>{balanceamount}</strong></span>
                    </div>
              </div>
              </div>
            
            </div>
          }