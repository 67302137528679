
import http from "./httpService";
import {
  apiUrl
} from "../config.json";

const apiEndpoint = apiUrl + "api/subscriber";

export function getAllSubscriber(subscriber) {
  return http.post(apiEndpoint+'/all', {
    json: subscriber
  });
}
export function getSMSSubscriber(msgData) {
  const apiEndpointSMS = apiEndpoint+'/sendsmsbulk';
  return http.post(apiEndpointSMS, 
    {
      json : msgData},
    {
  headers: {
    'Content-Type': 'application/json'
  }
});
}
export function getLoginSubscriber(subscriber) {
  const apiEndpointLogin = apiEndpoint+'/login';
  return http.post(apiEndpointLogin, {
    json: subscriber
  });
}

export function getVerifyContactLoginSubscriber(subscriber) {
  const apiEndpointLogin = apiEndpoint+'/login';
  return http.post(apiEndpointLogin, {
    json: subscriber
  });
}

export function register(subscriber) {
  return http.post(apiEndpoint, {
    json: subscriber
  });
}

