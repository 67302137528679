import React,{useRef,useEffect} from "react";

const SearchBox = ({ value, onChange, onKeyDown }) => {
  const inputEl = useRef(null);
  useEffect(()=>{
    inputEl.current.focus();
  }, []);
  return ( 
    <input
      type="text"
      name="query"
      className="form-control"
      placeholder="Search..."
      value={value}
      onChange={e => onChange(e.currentTarget.value)}
      onKeyDown={e=> onKeyDown(e)}
      ref={inputEl}
    />
  );
};

export default SearchBox;
