import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import SearchBox from '../components/common/searchBox';
import pickupbiz from '../styles/login/img/pickupbiz.svg';


const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
}));

export const Dashboard = (props) => {
  const [isSearch, setIsSerch] = useState(false);
  const classes = useStyles();
  
  const handleSearchIconClick = () =>{
    setIsSerch(true);
    (props.showAddNew) &&
      props.handleResetDashborad();
  }
  const handleBackIconClick = () =>{
    props.handleResetSearchText();
    setIsSerch(false);
  }
  const handleAddNewAdminClick= async()=>{
    await props.handleAddNewSubscriber();
    props.resetData();
  }
  const theme = createMuiTheme({
    overrides: {
      MuiToolbar:{
        root:{
         
          
          // border-bottom-color: orange;
          // border-bottom-style: outset;
          // border-bottom-width: medium;
        },
      },
      MuiAppBar:{
      colorPrimary:{
        backgroundColor: 'white',
        color: '#393185',
      },
    },
    },
  });

  return (
    <div>
    <MuiThemeProvider theme={theme}>
      <AppBar position="static">
          {
            (isSearch)
            ?
            <Toolbar className="pickupbizdashboardMain">
                <IconButton aria-label="show 4   mails" color="inherit" onClick = {handleBackIconClick}>
                   <ArrowBackIcon /> 
                </IconButton>
              
              <div className={classes.grow}>
                <SearchBox  
                onChange={e=>props.handleUpdateSearchText(e)}
                onKeyDown={props.handleLastDeleteTextKeyDown}
                 value={props.valueSearchText} />
              </div>
            </Toolbar>
            :
            <Toolbar className="pickupbizdashboardMain">
            <div className="pickupbizlogos">
              <img src={pickupbiz} alt="profile" className="logocss"/>
            </div>
            {/* <div className={classes.grow}> */}
              <div className="pickupbizdashboard">
                <IconButton aria-label="show 4   mails" color="inherit" onClick = {handleSearchIconClick}>
                  <SearchIcon /> 
                </IconButton>

                <IconButton aria-label="show 17 new notifications" color="inherit" onClick={handleAddNewAdminClick}>
                    <AddCircleIcon  />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={props.handleDisplayProfile}
                >
                  <AccountCircle />
                </IconButton>
              </div>
            {/* </div> */}
          </Toolbar>
          }
        
      </AppBar>
      </MuiThemeProvider>
    </div>
    
  );
}

export default Dashboard;