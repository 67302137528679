import React,{useState,useRef,useEffect} from "react";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import pickupbiz from '../styles/login/img/pickupbiz.svg';
import wave1 from '../styles/login/img/wave1.png';
import bg3 from '../styles/login/img/bg3.svg';

const Login = (props) => {
  const [contact, setContact] = useState("");
  const [password, setPassword] = useState("");
  const inputEl = useRef(null);

  useEffect(()=>{
    inputEl.current.focus();
  }, []);
  const getPassword=()=> {
    return <div>
    <TextField
      type="password"
      pattern="[0-9]*" 
      inputmode="numeric"
      id="password"
      label="Password"
      helperText={(props.errorPassword) && props.errors}
      error={props.errorPassword}
      fullWidth
      defaultValue={password}
      onChange={(event) => setPassword(event.target.value)}
      margin="normal"
      className="mt-1 input"    
    />
    </div>
  }

  const getContact=()=> {
    return <div>        
      <TextField
        id="contact"
        label="Contact"
        helperText={(props.errorContact) && props.errors}
        error={props.errorContact}
        fullWidth
        onChange={(event) => setContact(event.target.value)}
        defaultValue={contact}
        margin="normal"
        type="number"
        className="mt-1 input"
        inputRef={inputEl}
      />
    </div>
  }
  const getButton=()=> {
    return <div>
       <Button variant="contained" className="btn" color="primary"
                          onClick={()=>props.handleLoginClick(contact, password)}>Login</Button>
    </div>
  }


  const theme = createMuiTheme({
    overrides: {
     MuiButton: {
        root: {
          fontSize: '10',
          margin: '10',
          borderRadius: '20px',
          fontFamily: 'Poppins, sans-serif',
        },
        label: {
          color: '#fff',
        },
      },
      MuiButtonBase: {
        root: {
          marginTop: '3vmin',
          marginBottom: '1vmin',
          borderRadius: '20px',

        },
      },
    },
  });
    return (
      <div>
      <div className="wave"><img  src={wave1} alt="Wave" /></div>
          <div className="container2">

            <div className="img" >
              <img src={bg3} alt="Background 3" />
            </div>
              <div className="login-content2">
              
                <div className="form">
                  <div className="logo">
                      <img src={pickupbiz} width="155px" height="64px" alt="logo" />
                  </div>
                  {
                  props.loading 
                  ? 
                    <CircularProgress size={50}  /> 
                  : 
                  <div>
                      <div className="one">
                        {getContact()}
                          {/* <div className="i"> 
                            <i className="fas fa-user"></i>
                          </div> */}
                      </div>    

                      <div className="pass">
                        {getPassword()}
                        {/* <div className="i"> 
                            <i className="fas fa-lock"></i>
                        </div>*/}
                      </div>
                          <MuiThemeProvider theme={theme}>
                            {getButton()}
                          </MuiThemeProvider>
                        <div className="forgot"><a href="#">Forgot Password?</a></div>
                        <div className="hr"></div>
                        <div className="h6Tag" >Don't have an account? <a href="#">Sign Up</a></div>
                  </div>
                  }
                </div>
              
              </div>
            
          </div>
      </div>
   );
}

export default Login;