import React,{ useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {getAddPackageCategory} from "../services/packagecategoryService";


const PackageCategory=(props)=>{
  const [categoryname, setCategoryname]=useState("");
  const [amount, setAmount]=useState(0);
  const [numberofdays, setNumberofdays]=useState(0);
  const [duedays, setDuedays]= useState(0);
  const [description, setDescription] = useState("");
  const [priority, setPriority] = useState(0);
  const [isactive, setIsactive] = useState(1);
  const [modifiedby,setModifiedby] = useState(props.registrationno);
  const [openDlg, setOpenDlg] = useState(true);

  const [loading, setLoading] = useState(false);


 const loadInitialData = async () =>{
    
    
  }

  useEffect(
      ()=>{loadInitialData()}, []
  );

let savePackageCategory = async () => {
    const records = [{
      "categoryname": categoryname,
      "amount": amount,
      "numberofdays": numberofdays,
      "duedays": duedays,
      "description": description,
      "parentregistrationno" : props.parentregistrationno,
      "priority":priority,
      "isactive": isactive,
      "modifiedby": modifiedby
    }];
    const buildRecords = JSON.stringify(records).replace(/"/g, '\"');

    try {
        const response = await getAddPackageCategory(buildRecords);
        if(response){
          if (response.status === 200) {
            props.handleShowPackageCategory();
            //setEditProfile(false);
          }
          else {
            alert(`Registration of packagecategory - failed!`);
          }
        }
        else{
          console.log('Registration of packagecategory - failed');
        }
        
    }
      catch (ex) {
      if (ex.response && ex.response.status === 400) {
          //setErrors(ex.response.data);
      }
    }
  }
  
    const handleChangeCategoryName=(value)=>{
        setCategoryname(value);
    }
    const handleChangeAmount=(value)=>{
        setAmount(value);
    }
    const handleChangeNumberofdays=(value)=>{
        setNumberofdays(value);
    }
    const handleChangeDuedays=(value)=>{
      setDuedays(value);
    }
    const handleChangeDescription=(value)=>{
      setDescription(value);
    }
    const handlePriority=(value)=>{
      setPriority(value);
    }
    const handleChangeIsactive=(value)=>{
      setIsactive(value);
    }
    let handleSaveClick= async()=>{
        setOpenDlg(false);
     }
    
    let handleCloseDlg=()=>{
        setOpenDlg(false);
    }
    const classes = makeStyles(theme => ({
      root: {
        width: '100vw',
        height: '100vh',
        // backgroundColor: theme.palette.background.paper,
        backgroundColor: '#fff',
        marginleft:'5vmin',

      },
    }));
      return(
        // <>
        //   {
        //     (openDlg===true) ?
        //   <>
        //     {
             
           <div className="containerDataentryMain">
             <AppBar position="static">
                    <Toolbar onClick={props.handleShowPackageCategory}>
                      <IconButton edge="start" className={classes.menuButton} 
                      color="inherit" aria-label="menu" >
                        <CloseIcon />
                      </IconButton>
                      <Typography variant="h6" className={classes.title}>
                        Create Category - Package
                      </Typography>
                      
                    </Toolbar>
                </AppBar>
           <div className="containerDataentry">
            <>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <TextField
                    id="categoryName"
                    helperText={!categoryname && "Please enter the category"}
                    error={!categoryname}
                    onChange={e => handleChangeCategoryName(e.target.value)}
                    margin="normal"
                    placeholder="Category Name"
                    fullWidth
                  />
                </div>
              </div>
            </div>  
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <TextField
                    id="categoryAmount"
                    helperText={!amount && "Please enter the amount"}
                    error={!amount}
                    onChange={e => handleChangeAmount(e.target.value)}
                    type = "number"
                    margin="normal"
                    placeholder="Amount"
                    fullWidth
                  />
                </div>
              </div>
            </div>  
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <TextField
                    id="Numberofdays"
                    helperText={!numberofdays && "Please enter the Number of days"}
                    error={!numberofdays}
                    onChange={e => handleChangeNumberofdays(e.target.value)}
                    margin="normal"
                    type = "number"
                    placeholder="Number of days"
                    fullWidth
                  />
                </div>
              </div>
            </div>  
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <TextField
                    id="Duedays"
                    helperText={!duedays && "Please enter the Number of days"}
                    error={!duedays}
                    onChange={e => handleChangeDuedays(e.target.value)}
                    margin="normal"
                    type = "number"
                    placeholder="Due days"
                    fullWidth
                  />
                </div>
              </div>
            </div>  
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <TextField
                    id="Description"
                    helperText={!description && "Please enter the Description"}
                    error={!description}
                    onChange={e => handleChangeDescription(e.target.value)}
                    margin="normal"
                    placeholder="Description"
                    fullWidth
                  />
                </div>
              </div>
            </div>  
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <TextField
                    id="Priority"
                    helperText={!priority && "Please enter the Priority"}
                    error={!priority}
                    onChange={e => handlePriority(e.target.value)}
                    margin="normal"
                    type = "number"
                    placeholder="Priority"
                    fullWidth
                  />
                </div>
              </div>
            </div>  
            </>
            <Button onClick={savePackageCategory} variant="contained" disabled={loading} color="primary">
               Save
             </Button>
           </div>
             
          </div>    
          
          //}
        // </>
        //   :
        //   null
        //   }
        // </>
        );
    
}

export default PackageCategory;