import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import {
  getVerifyContactLoginSubscriber,
  register,
  getSMSSubscriber,
} from "../../services/subscriberService";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { create_UUID, uploadFiles } from "../../services/userService";
import { getAllCategory1 } from "../../services/category1Service";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import { DatePicker } from "material-ui-pickers";
import LocationSearchInput from "../common/LocationSearchInput";
import moment from "moment";
import Subscriber from "../Subscriber";
import imageCompression from "browser-image-compression";

function getSteps() {
  return [
    "Account Info",
    "Financial Info",
    "Personal Info",
    "Confirmation Info",
  ];
}

function getStepContent(stepIndex, props) {
  switch (stepIndex) {
    case 0:
      return getAccountInformation(props);
    case 1:
      return getSelectCategory(props);
    case 2:
      return getPersonalInformation(props);
    case 3:
      return getConfirmation(props);
    default:
      return "Uknown stepIndex";
  }
}

function getAccountInformation(props) {
  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <TextField
              id="contact"
              label="Contact"
              // onChange={e => props.handleOnchangeContact(e.target.value)}
              // value={props.contact}
              margin="normal"
              type="number"
              fullWidth
            />
          </div>
          {props.isError === true && (
            <p className="error-message">{props.errorMessage}</p>
          )}
        </div>
      </div>
      {(props.roletypeUserInfo === "SUBSCRIBER_ADMIN" &&
        props.showAddNewSecondryAdmin === false) ||
      props.roletypeUserInfo === "SUBSCRIBER_SEC_ADMIN" ? null : (
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <TextField
                id="password"
                label="password"
                type="password"
                pattern="[0-9]*"
                inputmode="numeric"
                autoComplete="current-password"
                margin="normal"
                fullWidth
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <TextField
                id="confirmPassword"
                label="Confirm Password"
                type="password"
                pattern="[0-9]*"
                inputmode="numeric"
                autoComplete="current-password"
                margin="normal"
                fullWidth
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function getSelectCategory(props) {
  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <FormControl component="fieldset" required>
              <FormLabel component="legend">Select Category</FormLabel>
              <RadioGroup
                className="d-flex flex-row"
                aria-label="gender"
                name="category1"
                value={props.categoryname}
                onChange={props.handleChangeRadioCategory}
              >
                {props.categoriesList.map((category) => {
                  return (
                    <FormControlLabel
                      key={category.id}
                      value={category.categoryname}
                      control={<Radio color="primary" />}
                      label={category.categoryname}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            {/* <TextField
        id="standard-select-currency-native"
        select 
        placeholder="Native select"
        className="currencyDataEntry"
        value={props.selectedCurrency}
        onChange={e => props.handleChangeCurrency(e.target.value)}
        SelectProps={{
          native: true,
        }}
      >
        {props.currencies.map(option => (
          <option key={option.value} value={option.value} className="currencyDataEntry">
            {option.label}
          </option>
        ))}
      </TextField>         */}

            <input
              id="paidAmount"
              className="inputDataEntry"
              value={props.paidamount}
              onChange={(e) => props.handleOnchangePaidAmount(e.target.value)}
              margin="normal"
              onClick={(e) => (e.target.value = "")}
              helperText="Please enter your paid amount"
              placeholder="Paid Amount"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <span className="inputDataEntryBalance">
              Balance: <strong>{props.balanceamount}</strong>
            </span>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            Start Date :
            <div key="basic_day" className="picker">
              <DatePicker
                fullWidth
                value={props.selectedDateStart}
                onChange={props.handleDateChangeStart}
                animateYearScrolling={false}
                leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            End Date :
            <div key="basic_day" className="picker">
              <DatePicker
                fullWidth
                value={props.selectedDateEnd}
                onChange={props.handleDateChangeEnd}
                animateYearScrolling={false}
                leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function getPersonalInformation(props) {
  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <TextField
              id="firstName"
              helperText={props.isFirstError && "Please enter the firstname"}
              error={props.isFirstError}
              onChange={(e) => props.handleOnchangeFirstName(e.target.value)}
              margin="normal"
              placeholder="First Name"
              fullWidth
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <TextField
              id="lastName"
              helperText={props.isLastError && "Please enter the lastname"}
              error={props.isLastError}
              placeholder="Last Name"
              value={props.lastname}
              onChange={(e) => props.handleOnchangeLastName(e.target.value)}
              margin="normal"
              fullWidth
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <LocationSearchInput />
          </div>
        </div>
        <div className="col-md-6">
          <FormControl component="fieldset" required>
            <FormLabel component="legend">Gender</FormLabel>
            <RadioGroup
              className="d-flex flex-row"
              aria-label="gender"
              name="gender"
              value={props.gender}
              onChange={props.handleChangeRadioGender}
            >
              <FormControlLabel
                value="male1"
                control={<Radio color="primary" />}
                label="Male"
              />
              <FormControlLabel
                value="female1"
                control={<Radio color="primary" />}
                label="Female"
              />
              <FormControlLabel
                value="other1"
                control={<Radio color="primary" />}
                label="NA"
              />
              {/*<FormControlLabel value="disabled" disabled control={<Radio />} label="Disabled" />*/}
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </div>
  );
}

function getConfirmation(props) {
  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <TextField
              id="remarks"
              value={props.remarks}
              onChange={(e) => props.handleOnchangeRemarks(e.target.value)}
              margin="normal"
              placeholder="Remarks"
              fullWidth
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input
              style={{ display: "none" }}
              type="file"
              name="file"
              onChange={props.onChangeHandlerPics}
              ref={props.fileInput}
              accept="image/*"
            />
          </div>
        </div>
        <div className="col-md-6">
          {props.loading ? <CircularProgress size={100} /> : null}
          {!props.selectedProfilePics && (
            <div className="form-group">
              <Button
                variant="contained"
                color="primary"
                onClick={() => props.fileInput.current.click()}
              >
                Pick Up profile photo
              </Button>
            </div>
          )}
          {props.selectedProfilePics && (
            <div>
              <img
                src={URL.createObjectURL(props.selectedProfilePics)}
                alt="profile"
                className="card_profilepicsPreview"
                onClick={() => props.fileInput.current.click()}
              />
            </div>
          )}
        </div>
      </div>
      <div className="tab-pane" id="tab2-4">
        <h3 className="title text-primary">Terms and Conditions</h3>
        <p>
          <strong>PickupBiz</strong> All data filled by is correct to my
          knowledge.
        </p>
        <div className="d-flex align-items-center">
          <Checkbox color="primary" />{" "}
          <span>I agree with the Terms and Conditions.</span>
        </div>
      </div>
    </div>
  );
}

class HorizontalLabelPositionBelowStepper extends React.Component {
  state = {
    activeStep: 0,
    registrationno: "",
    parentregistrationno: "",
    roletype: "SUBSCRIBER",
    roletypeUserInfo: "SUBSCRIBER_ADMIN",
    showAddNewSecondryAdmin: false,
    isError: false,
    isFirstError: false,
    isLastError: false,
    errorMessage: "",
    categoriesList: [],
    email: "",
    firstname: "",
    lastname: "",
    remarks: "",
    paidamount: 0,
    balanceamount: 0,
    name: "",
    gender: "male1",
    contact: "",
    passwordsalt: "",
    sociallinked: "",
    dateofbirth: "--",
    country: "",
    state: "",
    city: "",
    badges: "",
    locationSearch: "",
    certificates: "",
    categoryid: 1,
    categoryname: "Monthly",
    categoryCost: 100,
    numberofdays: 8,
    categorylevel2id: "",
    categorylevel3id: "",
    adress: "",
    onChangeHandlerPics: this.onChangeHandlerPics,
    handleChangeRadioGender: this.handleChangeRadioGender,
    handleChangeRadioCategory: this.handleChangeRadioCategory,
    onHandlerUplaod: this.onHandlerUplaod,
    selectedProfilePics: null,
    loading: false,
    handleOnchangeFirstName: this.handleOnchangeFirstName,
    handleOnchangeRemarks: this.handleOnchangeRemarks,
    handleOnchangePaidAmount: this.handleOnchangePaidAmount,
    handleChangeCurrency: this.handleChangeCurrency,
    handleOnchangeLastName: this.handleOnchangeLastName,
    handleOnchangeContact: this.handleOnchangeContact,
    handleOnchangeAddress: this.handleOnchangeAddress,
    handleOnchangeDOBdateDD: this.handleOnchangeDOBdateDD,
    handleOnchangeDOBdateMM: this.handleOnchangeDOBdateMM,
    handleOnchangeDOBdateYYYY: this.handleOnchangeDOBdateYYYY,
    handleOnchangeCountry: this.handleOnchangeCountry,
    handleOnchangeState: this.handleOnchangeState,
    handleOnchangeCity: this.handleOnchangeCity,
    handleDateChangeStart: this.handleDateChangeStart,
    selectedDateStart: moment(),
    handleDateChangeEnd: this.handleDateChangeEnd,
    selectedDateEnd: moment().add(30, "days"),
    progressValue: 0,
    fileInput: this.fileInput,
    currencies: [],
    showExistingSubscribers: false,
  };
  getInitialState = () => {
    this.setState({
      activeStep: 0,
      registrationno: "",
      parentregistrationno: "",
      roletype: "SUBSCRIBER",
      roletypeUserInfo: "SUBSCRIBER_ADMIN",
      showAddNewSecondryAdmin: false,
      isError: false,
      isFirstError: false,
      isLastError: false,
      errorMessage: "",
      categoriesList: [],
      email: "",
      firstname: "",
      lastname: "",
      remarks: "",
      paidamount: 0,
      selectedCurrency: "",
      balanceamount: 0,
      gender: "male1",
      name: "",
      contact: "",
      passwordsalt: "",
      sociallinked: "",
      dateofbirth: "--",
      country: "",
      state: "",
      city: "",
      locationSearch: "",
      badges: "",
      certificates: "",
      categoryid: "Monthly",
      categoryname: 1,
      categoryCost: 100,
      numberofdays: 8,
      categorylevel2id: "",
      categorylevel3id: "",
      address: "",
      onChangeHandlerPics: this.onChangeHandlerPics,
      handleChangeRadioGender: this.handleChangeRadioGender,
      handleChangeRadioCategory: this.handleChangeRadioCategory,
      selectedProfilePics: null,
      loading: false,
      handleOnchangeFirstName: this.handleOnchangeFirstName,
      handleOnchangeRemarks: this.handleOnchangeRemarks,
      handleOnchangePaidAmount: this.handleOnchangePaidAmount,
      handleChangeCurrency: this.handleChangeCurrency,
      handleOnchangeLastName: this.handleOnchangeLastName,
      handleOnchangeContact: this.handleOnchangeContact,
      handleOnchangeAddress: this.handleOnchangeAddress,
      handleOnchangeDOBdateDD: this.handleOnchangeDOBdateDD,
      handleOnchangeDOBdateMM: this.handleOnchangeDOBdateMM,
      handleOnchangeDOBdateYYYY: this.handleOnchangeDOBdateYYYY,
      handleOnchangeCountry: this.handleOnchangeCountry,
      handleOnchangeState: this.handleOnchangeState,
      handleOnchangeCity: this.handleOnchangeCity,
      handleDateChangeStart: this.handleDateChangeStart,
      selectedDateStart: moment(),
      handleDateChangeEnd: this.handleDateChangeEnd,
      selectedDateEnd: moment(),
      progressValue: 0,
      fileInput: this.fileInput,
      currencies: [],
      showExistingSubscribers: false,
    });
  };
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    this.getInitialState();
  }
  componentDidMount = async () => {
    const currencies = [
      {
        value: "RUPEE",
        label: "₹",
      },
      {
        value: "USD",
        label: "$",
      },
      {
        value: "EUR",
        label: "€",
      },
      {
        value: "BTC",
        label: "฿",
      },
      {
        value: "JPY",
        label: "¥",
      },
    ];

    let roletype = "SUBSCRIBER";
    let parentregistrationno = this.props.userInfo.registrationno;
    const roletypeUserInfo = this.props.userInfo.roletype;
    switch (this.props.userInfo.roletype) {
      case "FULL_CONTROL":
        roletype = this.props.showAddNewSecondryAdmin
          ? "FULL_CONTROL_SEC_ADMIN"
          : "SUBSCRIBER_ADMIN";
        break;
      case "FULL_CONTROL_SEC_ADMIN":
        roletype = "SUBSCRIBER_ADMIN";
        parentregistrationno = this.props.userInfo.parentregistrationno;
        break;
      case "SUBSCRIBER_ADMIN":
        roletype = this.props.showAddNewSecondryAdmin
          ? "SUBSCRIBER_SEC_ADMIN"
          : "SUBSCRIBER";
        break;
      case "SUBSCRIBER_SEC_ADMIN":
        parentregistrationno = this.props.userInfo.parentregistrationno;
        roletype = "SUBSCRIBER";
        break;
      default:
        roletype = "SUBSCRIBER";
    }

    const registrationno = await create_UUID();
    let categoriesList = await getAllCategory1(parentregistrationno);
    categoriesList = categoriesList.data[0];
    //categoriesList = categoriesList.data[0].filter(category=> category.parentregistrationno === parentregistrationno)
    const categoryid = categoriesList && categoriesList[0].id;
    const categoryname = categoriesList && categoriesList[0].categoryname;
    const numberofdays = categoriesList && categoriesList[0].numberofdays;
    const categoryCost = categoriesList && categoriesList[0].amount;
    const selectedDateEnd = moment(this.state.selectedDateStart).add(
      numberofdays,
      "days"
    );
    const balanceamount = categoryCost;

    if (registrationno) {
      this.setState({
        registrationno,
        parentregistrationno,
        categoriesList,
        categoryid,
        categoryname,
        numberofdays,
        selectedDateEnd,
        categoryCost,
        balanceamount,
        currencies,
        roletype,
        roletypeUserInfo,
        showAddNewSecondryAdmin: this.props.showAddNewSecondryAdmin,
      });
    }
  };
  saveSubscriber = async () => {
    const records = [
      {
        registrationno: this.state.registrationno,
        roletype: this.state.roletype,
        mobile: this.state.contact,
        parentregistrationno: this.state.parentregistrationno,
        country: this.state.country,
        state: this.state.state,
        city: this.state.city,
        address: this.state.locationSearch,
        category_lastsubs: this.state.categoryid,
        cost_lastsubs: this.state.categoryCost,
        currpaidamount: this.state.paidamount,
        currbalanceamount: this.state.balanceamount,
        startdate: this.state.selectedDateStart._d.toISOString().split("T")[0],
        enddate: this.state.selectedDateEnd._d.toISOString().split("T")[0],
        lastlat: 21.7679,
        lastlng: 78.8718,
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        remarks: this.state.remarks,
        email: this.state.email,
        pswrdsalt: this.state.passwordsalt,
        sociallinked: this.state.sociallinked,
        lastbalanceamount: 0,
        istransaction: true,
        modifiedby: this.props.userInfo.registrationno,
      },
    ];
    const startDateData = this.state.selectedDateStart._d.toLocaleString(
      "default",
      { day: "numeric", month: "long", year: "numeric" }
    );
    const buildRecords = JSON.stringify(records).replace(/"/g, '"');
    const msgData = `Dear ${this.state.firstname} ${this.state.lastname},\nYou are registered with ${this.props.userInfo.firstname} ${this.props.userInfo.lastname}.\n\nRegistration details:\nStart Date: ${startDateData}\nPaid Amount:${this.state.paidamount}\nBalance Amount:${this.state.balanceamount}\n\nFor any further info contact us: ${this.props.userInfo.contact}`;
    const msgDataProducts = {
      messageTo: msgData,
      phoneTo: this.state.contact,
    };
    try {
      const response = await register(buildRecords);
      if (response) {
        if (response.status === 200) {
          this.setState({
            showExistingSubscribers: true,
          });
          this.props.resetData();
          const responseSMS = await getSMSSubscriber(msgDataProducts);
          if (responseSMS) {
            if (responseSMS.status === 200) {
              console.log("SMS has been sent successfully!!");
            }
          }
        } else {
          alert(`Registration of subscriber - failed!`);
        }
      } else {
        console.log("Registration of subscriber - failed");
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = {
          ...this.state.errors,
        };
        this.setState({
          errors,
        });
      }
    }
  };
  getValueById = (Id) => {
    const result = document.getElementById(Id);
    if (result) {
      return result.value;
    }
    return "";
  };
  getValueByClassName = (classname) => {
    const result = document.getElementsByClassName(classname);
    try {
      if (result) {
        return result[0];
      }
      return "";
    } catch (ex) {
      return null;
    }
  };
  callback = () => {
    getStepContent(this.state.activeStep, this.state);
  };
  handleChangeCurrency = (value) => {
    this.setState({ selectedCurrency: value });
  };

  handleOnchangePaidAmount = (value) => {
    const balanceamount = this.state.categoryCost - value;
    this.setState({
      paidamount: value,
      balanceamount,
    });
  };
  handleOnchangeFirstName = (value) => {
    this.setState({
      firstname: value,
      name: `${value} ${this.state.lastname}`,
    });
  };

  handleOnchangeLastName = (value) => {
    this.setState({
      lastname: value,
      name: `${this.state.firstname} ${value}`,
    });
  };
  handleOnchangeRemarks = (value) => {
    this.setState({
      remarks: value,
    });
  };
  handleOnchangeContact = (value) => {
    this.setState({
      contact: value,
    });
  };

  handleOnchangeAddress = (value) => {
    this.setState({
      address: value,
    });
  };

  handleOnchangeDOBdateDD = (value) => {
    this.setState({
      dateDD: value,
    });
  };

  handleOnchangeDOBdateMM = (value) => {
    this.setState({
      dateMM: value,
    });
  };

  handleOnchangeDOBdateYYYY = (value) => {
    this.setState({
      dateYYYY: value,
    });
  };

  handleOnchangeCountry = (value) => {
    this.setState({
      country: value,
    });
  };
  handleOnchangeState = (value) => {
    this.setState({
      state: value,
    });
  };
  handleOnchangeCity = (value) => {
    this.setState({
      city: value,
    });
  };

  handleDateChangeStart = (date) => {
    const selectedDateEnd = moment(date).add(this.state.numberofdays, "days");
    this.setState({ selectedDateStart: date, selectedDateEnd });
  };
  handleDateChangeEnd = (date) => {
    this.setState({ selectedDateEnd: date });
  };
  getVerifyContactUserInfo = async (contact) => {
    const records = [
      {
        username: contact,
        parentregistrationno: this.state.parentregistrationno,
        verifycontact: true,
      },
    ];

    let buildRecords = JSON.stringify(records).replace(/"/g, '"');

    try {
      const response = await getVerifyContactLoginSubscriber(buildRecords);
      if (response) {
        if (response.status === 200) {
          this.setState({
            showSubscribers: true,
            userInfo: response.data[0][0],
          });
          return response;
        } else {
          alert(`Fetching of userInfo - failed!`);
        }
      } else {
        console.log("getVerifyContactLoginSubscriber - Failed to verify");
        return null;
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = {
          ...this.state.errors,
        };
        this.setState({
          errors,
        });
      }
    }
  };
  handleNext = async (callback) => {
    const steps = getSteps();
    const { activeStep } = this.state;

    if (activeStep === steps.length - 1) {
      this.saveSubscriber();
    }

    const sportsCategoryClass = document.getElementById("sportsCategorySingle");
    const sportsCategory =
      sportsCategoryClass === null ? "" : sportsCategoryClass.innerHTML;
    const sportsSpecializationClass = document.getElementById(
      "sportsSpecializationSingle"
    );
    const sportsSpecialization =
      sportsSpecializationClass === null
        ? ""
        : sportsSpecializationClass.children[0].innerHTML;
    const arrAddress = this.getValueById("locationSearch").split(",");
    const arrAddressCount = arrAddress.length;

    this.setState({
      registrationno: this.state.registrationno,
      categoriesList: this.state.categoriesList,
      email:
        this.state.email === "" ? this.getValueById("email") : this.state.email,
      firstname:
        this.state.firstname === ""
          ? this.getValueById("firstName")
          : this.state.firstname,
      lastname:
        this.state.lastname === ""
          ? this.getValueById("lastName")
          : this.state.lastname,
      remarks:
        this.state.remarks === ""
          ? this.getValueById("remarks")
          : this.state.remarks,
      paidamount:
        this.state.paidamount === ""
          ? this.getValueById("paidAmount")
          : this.state.paidamount,
      selectedCurrency: this.state.selectedCurrency,
      balanceamount: this.state.balanceamount,
      gender: this.state.gender,
      contact:
        this.state.contact === ""
          ? this.getValueById("contact")
          : this.state.contact,
      city:
        this.state.locationSearch === "" && arrAddressCount > 1
          ? arrAddress[arrAddressCount - 3] &&
            arrAddress[arrAddressCount - 3].trim()
          : this.state.city,
      state:
        this.state.locationSearch === "" && arrAddressCount > 1
          ? arrAddress[arrAddressCount - 2].trim()
          : this.state.state,
      country:
        this.state.locationSearch === "" && arrAddressCount > 1
          ? arrAddress[arrAddressCount - 1].trim()
          : this.state.country,
      locationSearch:
        this.state.locationSearch === ""
          ? this.getValueById("locationSearch")
          : this.state.locationSearch,
      passwordsalt:
        this.state.passwordsalt === ""
          ? this.getValueById("password")
          : this.state.passwordsalt,
      sociallinked: "none",
      dateofbirth:
        this.state.dateofbirth === "--"
          ? `${this.getValueById("dateYYYY")}-${this.getValueById(
              "dateMM"
            )}-${this.getValueById("dateDD")}`
          : this.state.dateofbirth,
      badges:
        this.state.badges === ""
          ? this.getValueById("badges")
          : this.state.badges,
      certificates:
        this.state.certificates === ""
          ? this.getValueById("certificates")
          : this.state.certificates,
      categoryid: this.state.categoryid,
      categoryname: this.state.categoryname,
      categoryCost: this.state.categoryCost,
      numberofdays: this.state.numberofdays,
      categorylevel2id:
        this.state.categorylevel2id === ""
          ? sportsCategory
          : this.state.categorylevel2id,
      categorylevel3id:
        this.state.categorylevel3id === ""
          ? sportsSpecialization
          : this.state.categorylevel3id,
      address:
        this.state.address === ""
          ? this.getValueById("address")
          : this.state.adress,
      lastlat: 0,
      lastlng: 0,
      onChangeHandlerPics: this.onChangeHandlerPics,
      handleChangeRadioGender: this.handleChangeRadioGender,
      handleChangeRadioCategory: this.handleChangeRadioCategory,
      onHandlerUplaod: this.onHandlerUplaod,
      selectedProfilePics: this.state.selectedProfilePics,
      loading: this.state.loading,
      handleOnchangeFirstName: this.handleOnchangeFirstName,
      handleOnchangeRemarks: this.handleOnchangeRemarks,
      handleOnchangePaidAmount: this.handleOnchangePaidAmount,
      handleChangeCurrency: this.handleChangeCurrency,
      handleOnchangeLastName: this.handleOnchangeLastName,
      handleOnchangeContact: this.handleOnchangeContact,
      handleOnchangeAddress: this.handleOnchangeAddress,
      handleOnchangeDOBdateDD: this.handleOnchangeDOBdateDD,
      handleOnchangeDOBdateMM: this.handleOnchangeDOBdateMM,
      handleOnchangeDOBdateYYYY: this.handleOnchangeDOBdateYYYY,
      handleOnchangeCountry: this.handleOnchangeCountry,
      handleOnchangeState: this.handleOnchangeState,
      handleOnchangeCity: this.handleOnchangeCity,
      handleDateChangeStart: this.handleDateChangeStart,
      selectedDateStart: this.state.selectedDateStart,
      handleDateChangeEnd: this.handleDateChangeEnd,
      selectedDateEnd: this.state.selectedDateEnd,
      progressValue: this.state.progressValue,
      fileInput: this.fileInput,
      currencies: this.state.currencies,
    });

    switch (activeStep) {
      case 0: {
        const contact = this.getValueById("contact");
        const result = await this.getVerifyContactUserInfo(contact);
        if (result && result.data[0].length > 0) {
          this.setState({
            isError: true,
            errorMessage: `This contact is already registered, 
                              please use another contact number.`,
          });
        } else {
          this.setState({
            activeStep: activeStep + 1,
            contact: contact,
            isError: false,
            errorMessage: "",
          });
        }
        return;
      }
      case 1: {
        this.setState({
          activeStep: activeStep + 1,
        });
        return;
      }
      case 2: {
        const firstName = this.getValueById("firstName");
        const lastName = this.getValueById("lastName");
        const locationSearch = this.getValueById("locationSearch");
        const isFirstError = firstName.length > 0 ? false : true;
        const isLastError = lastName.length > 0 ? false : true;
        const isError = locationSearch === "" ? true : false;
        const errorMessage =
          locationSearch === "" ? "Please enter the address..." : "";

        if (isFirstError || isLastError || isError) {
          this.setState({
            isFirstError,
            isLastError,
            isError,
            errorMessage,
          });
        } else {
          this.setState({
            activeStep: activeStep + 1,
            firstName,
            lastName,
            locationSearch,
            isFirstError,
            isLastError,
            isError,
            errorMessage,
          });
        }
        return;
      }
      case 3: {
        const isError = this.state.locationSearch === "" ? true : false;
        const errorMessage =
          this.state.locationSearch === "" ? "Please enter the address..." : "";
        this.setState({
          activeStep: activeStep + 1,
          isError,
          errorMessage,
        });
        return;
      }
      default: {
        this.setState({
          activeStep: activeStep + 1,
        });
      }
    }
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep - 1,
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  handleChangeRadioGender = (event, value) => {
    this.setState({ gender: value });
  };

  handleChangeRadioCategory = (event, value) => {
    const currCategory = this.state.categoriesList.filter(
      (category) => category.categoryname === value
    );
    const categoryname = currCategory[0].categoryname;
    const categoryid = currCategory[0].id;
    const numberofdays = currCategory[0].numberofdays;
    const categoryCost = currCategory[0].amount;
    const selectedDateEnd = moment(this.state.selectedDateStart).add(
      numberofdays,
      "days"
    );
    const balanceamount = categoryCost - this.state.paidamount;
    this.setState({
      categoryid,
      categoryname,
      categoryCost,
      numberofdays,
      selectedDateEnd,
      balanceamount,
    });
  };

  fileProfileSelected = (compressedFile) => {
    //console.log(event.target.files[0]);
    this.setState({
      selectedProfilePics: compressedFile,
      loading: false,
      progressValue: 25,
    });
  };

  onChangeHandlerPics_old = async (event) => {
    await this.fileProfileSelected(event);
  };

  onChangeHandlerPics = async (event) => {
    const imageFile = event.target.files[0];
    var options = {
      maxSizeMB: 12 / 1024,
      maxWidthOrHeight: 280,
      useWebWorker: true,
    };
    try {
      this.setState({
        loading: true,
      });
      const compressedFile = await imageCompression(imageFile, options);
      // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      // console.log(`compressedFile size ${compressedFile.size / 1024 } KB`); // smaller than maxSizeMB
      await this.fileProfileSelected(compressedFile);
      await this.onHandlerUplaod(compressedFile); // write your own logic
    } catch (error) {
      console.log(error);
    }
  };

  onHandlerUplaod = async (compressedFile) => {
    try {
      this.setState({
        progressValue: 50,
      });
      const responseUpload = await uploadFiles(compressedFile, {
        registrationno: this.state.registrationno,
      });
      this.setState({
        progressValue: 100,
      });
      if (responseUpload) {
        console.log("Uploaded successfully");
      }
    } catch (ex) {
      console.log("Uploaded issue please re-upload");
    }
  };

  // callbackupload=(res)=>{
  //   console.log("Uploaded successfully");
  // }

  render() {
    const themeMui = createMuiTheme({
      overrides: {
        MuiTypography: {
          body1: {
            margin: "0",
            padding: "0",
            "font-family": "Roboto, Helvetica, Arial, sans-serif",
            "font-size": "4vmin",
          },
        },
        MuiFormLabel: {
          root: {
            margin: "0",
            padding: "0",
            paddingTop: "10px",
            "font-family": "Roboto, Helvetica, Arial, sans-serif",
            "font-size": "3vmin",
          },
        },
        MuiInputBase: {
          input: {
            // "margin":"0",
            // "padding":"0",
            "font-family": "Roboto, Helvetica, Arial, sans-serif",
            "font-size": "4vmin",
          },
        },
        MuiFormControlLabel: {
          root: {
            margin: "0",
            padding: "0",
            "font-family": "Roboto, Helvetica, Arial, sans-serif",
            "font-size": "4vmin",
          },
        },
        MuiButton: {
          root: {
            "font-size": "2.5vmin",
            "min-width": "4vmin",
            "background-color": "#28a745",
            "margin-left": "0.5vmin",
          },
        },
      },
    });
    const classes = makeStyles((theme) => ({
      root: {
        width: "100vw",
        height: "100vh",
        // backgroundColor: theme.palette.background.paper,
        backgroundColor: "#fff",
        marginleft: "5vmin",
      },
    }));
    const steps = getSteps();
    const { activeStep } = this.state;

    // return (
    //   <MyContext.Consumer>
    //   {(context)=>{
    return this.state.showExistingSubscribers ? (
      <Subscriber userInfo={this.props.userInfo} />
    ) : (
      // <Subs userInfo= {this.props.userInfo} />
      <MuiThemeProvider theme={themeMui}>
        <div className="containerDataentryMain">
          <AppBar position="static">
            <Toolbar onClick={this.props.handleAddNewSecondryAdminClose}>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {this.state.showAddNewSecondryAdmin
                  ? "Add User"
                  : "Add Subscriber"}
              </Typography>
            </Toolbar>
          </AppBar>
          <div className="containerDataentry">
            <div>
              {this.state.activeStep === steps.length ? (
                <div>
                  <Typography className="my-2">
                    All steps completed - you&quot;re finished
                  </Typography>
                  <Button onClick={this.handleReset}>Reset</Button>
                </div>
              ) : (
                <div>
                  {getStepContent(activeStep, this.state)}
                  <div className="containerDataentryButton">
                    <Button
                      disabled={activeStep === 0}
                      onClick={this.handleBack}
                      className="mr-2"
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleNext}
                      disabled={
                        activeStep === steps.length - 1 &&
                        this.state.selectedProfilePics === null
                          ? true
                          : false
                      }
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            className="horizontal-stepper-linear"
          >
            {steps.map((label, index) => {
              return (
                <Step
                  key={label}
                  className={`horizontal-stepper ${
                    index === activeStep ? "active" : ""
                  }`}
                >
                  <StepLabel className="stepperlabel">{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>
      </MuiThemeProvider>
    );
  }
  //    }
  //  </MyContext.Consumer>
  //       );
  // }
}

export default HorizontalLabelPositionBelowStepper;
