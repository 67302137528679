import React from 'react';
import './todo.css'
import SubscriberProfile from '../components/profilewidget/SubscriberProfile';
import { async } from 'q';

    class TodoApp extends React.Component {
      constructor(props) {
        super(props);
        // const totalPagesCount = Math.ceil(this.props.data.length / todosPerPage);
        
        //this.renderPageNumbersIniti2();
      }
      state = {
        currentPage: 1,
        todosPerPage:9,
        startPage:1,
        endPage:1,
        totalPagesCount:0,
        pageDisplayCount:6,
        refreshPagination:false,
        pageNumbers: [2,3],
      };
      componentDidMount=()=> {
        
        // this.setState = ({
        //   currentPage: 1,
        //   todosPerPage:9,
        //   startPage:1,
        //   pageDisplayCount:6,
        //   refreshPagination:false,
          
        //   pageNumbers: [],
        // });
      }
      handleClick=(event)=> {
        this.setState({
          currentPage: Number(event.target.id)
        });
      }

      handleNextPaginationClick=()=>{
        if(this.state.totalPagesCount>=(this.state.startPage + this.state.pageDisplayCount))
        {
          this.setState(
            {
              startPage: this.state.endPage + 1,
              refreshPagination: false,
            }
          )
        }
      }

      handlePrevPaginationClick=()=>{
        if(this.state.startPage>1)
        {
          this.setState(
            {
              startPage: this.state.startPage - this.state.pageDisplayCount,
              refreshPagination: false,
            }
          )
        }
      }

      renderPageNumbersIniti2 = async () =>{
        
        if(this.props.data && this.state.refreshPagination===false && this.props.data.length>0)
        {
          const pageNumbers = await this.renderPageNumbersIniti();
          const currPagingCount = pageNumbers.length;
          if(currPagingCount>0){
          this.setState({
               pageNumbers,
               refreshPagination:true,
               startPage: pageNumbers[0],
               endPage: pageNumbers[currPagingCount-1],
             });
            }
        }
      
      }
    
      renderPageNumbersIniti = () => {
        const totalPagesCount= Math.ceil(this.props.data.length / this.state.todosPerPage);
        if(this.state.totalPagesCount===0){
          this.setState(
            {
              totalPagesCount,
            }
          )
        }
          
        const pageNumbers=[];
        let pageDisplayCount = (this.state.totalPagesCount<this.state.pageDisplayCount) ? this.state.totalPagesCount : this.state.pageDisplayCount;
        //((this.state.totalPagesCount===(this.state.startPage + this.state.pageDisplayCount))?this.state.pageDisplayCount:this.state.totalPagesCount
        pageDisplayCount = ((pageDisplayCount+this.state.startPage) > totalPagesCount) ? totalPagesCount+1 : (pageDisplayCount + this.state.startPage);
        let i = this.state.startPage;
      for (i; i < (pageDisplayCount); i++) {
        pageNumbers.push(i);
    };
      return pageNumbers;
      
    }

      render() {
         // Logic for displaying current this.props.data
         const indexOfLastTodo = this.state.currentPage * this.state.todosPerPage;
         const indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;
         const currentTodos = this.props.data && this.props.data.slice(indexOfFirstTodo, indexOfLastTodo);
         const renderTodos = currentTodos && currentTodos.map((currdata, index) => {
          return <SubscriberProfile key={index} data={currdata} 
             className='container' handleClickProfile={this.props.handleClickProfile} />
         
        });
        
        this.renderPageNumbersIniti2();
         
        const renderPageNumbers =
        this.state.pageNumbers.map(number => {
          return(
          <li
            key={number}
            id={number}
            onClick={this.handleClick}
            className="pagination"
          >
             {number}
          </li>         
        )
      });
        

        return (
          <>
          <div className="AppContainerMainPlaceholderCards">
              <div  className="row">
                {renderTodos}
              </div>
                
          </div>
          <div  className="row">
          <ul id="page-numbers" className="pagination">         
            <li className="pagination"
            onClick={this.handlePrevPaginationClick}>&laquo;</li>
            {
            this.state.refreshPagination === true &&  
            <>
              {renderPageNumbers}
            </>
            }
            <li className="pagination"
            onClick={this.handleNextPaginationClick}
            >&raquo;</li>
          </ul>
          </div>
          </>
        );
      }
    }

    export default TodoApp;